// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"


require("packs/modernizr-3.6.0.min")
require("bootstrap/dist/js/bootstrap");
require("packs/slick.min")
require("packs/odometer.min")
require("packs/jquery.appear.min")
require("packs/jquery.nice-select.min")
require("packs/jquery.magnific-popup.min")
require("packs/main")

Rails.start()
Turbolinks.start()
ActiveStorage.start()
